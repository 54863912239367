import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useStore } from '../../Models/RootStore'
import { Colors } from '../../Utils/theme'
import { AdminHeader, AdminContent, PlayerRow, ScreenStatusRow, MobileScreenRow, Input, Button, Checkbox } from '../../Components'
import { League, Gender } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: '100vh',
    padding: '1rem',
    backgroundColor: Colors.brandPrimary,
    [theme.breakpoints.down('md')]: {
      padding: '1rem .25rem',
      overflowX: 'auto'
    }
  },
  title: {
    fontSize: '3.25rem',
    margin: 0,
    padding: 0,
    color: Colors.white,
    fontWeight: 800,
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem'
    }
  },
  row: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    // Mobile styles
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    }
  },
  columnLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '1rem'
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row'
  },
  listContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  addNewLink: {
    cursor: 'pointer',
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    display: 'flex',
    justifyContent: 'center',
    color: Colors.white,
    textDecoration: 'none',
    border: `2px solid ${Colors.white}`,
    borderRadius: '.25rem',
    fontWeight: 600,
    fontSize: '1.125rem',
    padding: '.5rem 2rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      fontSize: '.85rem',
      padding: '.375rem 1rem'
    }
  },
  refreshButton: {
    marginTop: '1rem',
    width: '8rem',
    border: `2px solid ${Colors.white}`,
    margin: 0,
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      height: '2.25rem',
      width: '6rem'
    }
  },
  refreshButtonText: {
    color: Colors.white,
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '.85rem'
    }
  },
  screensContainer: {
    paddingBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
    marginRight: '-1rem',
    // Mobile styles
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      marginRight: 0
    }
  },
  activeScreenContainer: {
    flex: 1,
    paddingRight: '1rem',
    paddingBottom: '.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      paddingBottom: 0
    }
  },
  inactiveScreenContainer: {
    flex: 1,
    paddingRight: '1rem',
    paddingBottom: '.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      display: 'none',
      paddingRight: 0,
      paddingBottom: 0
    }
  },
  mobileScreenSelectContainer: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      marginTop: '.5rem',
      marginBottom: '.5rem',
      display: 'flex',
      flexDirection: 'row'
    }
  },
  searchContainer: {
    // Mobile styles
    flex: 1,
    [theme.breakpoints.down('lg')]: {
      paddingTop: '1rem',
    }
  },
  filterTitle: {
    fontWeight: 700,
    color: Colors.white,
    marginBottom: '-.25rem'
  },
  radioRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioItem: {
    marginLeft: '-.5rem',
    marginRight: '1.5rem',
    display: 'flex',
    flexDirection: 'row'
  },
  radioLabel: {
    paddingTop: '.75rem',
    fontSize: '1rem',
    color: Colors.white,
    fontWeight: 400
  },
}))

const Settings = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { playerStore, screenStore, recordStore, configStore } = useStore()

  const [search, setSearch] = useState('')
  const [activePlayerId, setActivePlayerId] = useState(null)
  const [activeScreenId, setActiveScreenId] = useState(null)

  const [genderFilter, setGenderFilter] = useState<string|null>(null)
  const [leagueFilter, setLeagueFilter] = useState<string|null>(null)

  const handleSetActivePlayerId = (id) => {
    const newActivePlayerId = activePlayerId === id ? null : id
    recordStore.setStatus({ playerId: newActivePlayerId })
  }

  const handleSetActiveScreenId = (id) => {
    const newActiveScreenId = activeScreenId === id ? null : id
    recordStore.setStatus({ screenId: newActiveScreenId, playerId: null })
  }

  useEffect(() => {
    setActiveScreenId(get(recordStore.status, 'screenId', null))
  }, [recordStore.status])

  useEffect(() => {
    setActivePlayerId(get(recordStore.status, 'playerId', null))
  }, [recordStore.status])

  useEffect(() => {
    recordStore.getStatus()
    screenStore.getScreens()
    playerStore.getPlayers()
    recordStore.getLatestRecord()
    recordStore.startGetLatestRecordPolling()
    configStore.getConfigs()
  }, [])

  const getIsImperial = () => {
    const measurementSystemConf = (configStore.configs || []).find(item => item.key === 'measurement_system')
    if (measurementSystemConf && measurementSystemConf.value === 'imperial') {
      return true
    }
    return false
  }

  const getPlayers = () => {
    let players = playerStore.players

    if (leagueFilter) {
      players = players.filter(p => p.league === leagueFilter)
    }

    if (genderFilter) {
      players = players.filter(p => p.gender === genderFilter)
    }

    const searchParts = search.trim().split(' ').filter(val => !!val).map(val => val.toLowerCase())

    const searchFields = [
      'firstName',
      'lastName',
      'email',
      'phone'
    ]

    if (searchParts.length) {
      players = players.filter(player => {
        // Create search string
        const searchString = searchFields.map(field => get(player, field) || '').join(' ').toLowerCase()

        // Make sure all search parts are found
        let exists = true
        for (const searchPart of searchParts) {
          if (!searchString.includes(searchPart)) exists = false
        }
        return exists
      })
    }

    return players
  }

  const renderSearch = () => {
    return (
      <div className={classes.searchContainer}>
        <Input
          placeholder={t('search')}
          value={search}
          onChange={setSearch}
          noMargin
        />
      </div>
    )
  }

  const renderPlayerList = () => {
    return getPlayers().map((player, index) => {
      return (
        <PlayerRow
          key={player.id}
          item={player}
          selected={activePlayerId === player.id}
          toggleSelected={() => handleSetActivePlayerId(player.id)}
          displayHeading={index === 0}
        />
      )
    })
  }

  const renderScreen = (screen) => {
    let activePlayer = null
    if (activeScreenId === screen.id && activePlayerId) {
      activePlayer = playerStore.players.find(p => p.id === activePlayerId)
    }
    let latestRecord = null
    if (recordStore.latestRecord && recordStore.latestRecord.screenId === screen.id) {
      latestRecord = recordStore.latestRecord
    }

    const waiting = activePlayer && activePlayer.id !== get(latestRecord, 'playerId')
    if (waiting) {
      latestRecord = null
    }

    return (
      <ScreenStatusRow
        activePlayer={activePlayer}
        latestRecord={latestRecord}
        screen={screen}
        selected={activeScreenId === screen.id}
        toggleSelected={() => handleSetActiveScreenId(screen.id)}
        clearActivePlayer={() => handleSetActivePlayerId(null)}
        imperial={getIsImperial()}
        waiting={waiting}
      />
    )
  }

  const renderMobileScreen = (screen) => {
    return (
      <MobileScreenRow
        screen={screen}
        selected={activeScreenId === screen.id}
        toggleSelected={() => handleSetActiveScreenId(screen.id)}
      />
    )
  }

  const renderMobileScreenSelectors = () => {
    return (
      <div className={classes.mobileScreenSelectContainer}>
        {screenStore.screens.map((screen, index) => {
          return (
            <React.Fragment key={index}>
              {renderMobileScreen(screen)}
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  const renderScreens = () => {
    return (
      <div className={classes.screensContainer}>
        {screenStore.screens.map((screen, index) => {
          // We hide inactive screen in mobile widths
          const containerClass = activeScreenId === screen.id ? classes.activeScreenContainer : classes.inactiveScreenContainer
          return (
            <div key={index} className={containerClass}>
              {renderScreen(screen)}
            </div>
          )
        })}
      </div>
    )
  }

  const renderLeagueFilters = () => {
    return (
      <div className={classes.radioRow}>
        <div className={classes.radioItem}>
          <Checkbox
            checked={leagueFilter === null}
            onChange={() => setLeagueFilter(null)}
            radio
          />
          <div className={classes.radioLabel}>{t('all_untranslated')}</div>
        </div>
        <div className={classes.radioItem}>
          <Checkbox
            checked={leagueFilter === League.Amateur}
            onChange={() => setLeagueFilter(League.Amateur)}
            radio
          />
          <div className={classes.radioLabel}>{t('amateur_untranslated')}</div>
        </div>
        <div className={classes.radioItem}>
          <Checkbox
            checked={leagueFilter === League.Pro}
            onChange={() => setLeagueFilter(League.Pro)}
            radio
          />
          <div className={classes.radioLabel}>{t('pro_untranslated')}</div>
        </div>
      </div>
    )
  }

  const renderGenderFilters = () => {
    return (
      <div className={classes.radioRow}>
        <div className={classes.radioItem}>
          <Checkbox
            checked={genderFilter === null}
            onChange={() => setGenderFilter(null)}
            radio
          />
          <div className={classes.radioLabel}>{t('all_untranslated')}</div>
        </div>
        <div className={classes.radioItem}>
          <Checkbox
            checked={genderFilter === Gender.Male}
            onChange={() => setGenderFilter(Gender.Male)}
            radio
          />
          <div className={classes.radioLabel}>{t('male_untranslated')}</div>
        </div>
        <div className={classes.radioItem}>
          <Checkbox
            checked={genderFilter === Gender.Female}
            onChange={() => setGenderFilter(Gender.Female)}
            radio
          />
          <div className={classes.radioLabel}>{t('female_untranslated')}</div>
        </div>
      </div>
    )
  }

  const renderFilters = () => {
    return (
      <>
        <div className={classes.filterTitle}>{t('series_filter')}</div>
        {renderLeagueFilters()}
        <div className={classes.filterTitle}>{t('gender_filter')}</div>
        {renderGenderFilters()}
      </>
    )
  }

  return (
    <>
      <AdminHeader />
      <AdminContent>
        <div className={classes.content}>
          <h2 className={classes.title}>{t('active_tee_and_player')}</h2>
          {renderMobileScreenSelectors()}
          {renderScreens()}
          <div className={classes.row}>
            <div className={classes.columnLeft}>
              <h2 className={classes.title}>{t('players')}</h2>
              <div>
                <a href='/register' target='_blank' rel='noreferrer' className={classes.addNewLink}>
                  Add new
                </a>
              </div>
              <div>
              <Button
                text={t('refresh')}
                onClick={() => playerStore.getPlayers()}
                buttonStyle={classes.refreshButton}
                buttonTextStyle={classes.refreshButtonText}
                loading={playerStore.loading}
                outlined
                noMargin
              />
              </div>
            </div>
            <div className={classes.centerColumn}>
              {renderFilters()}
            </div>
            <div className={classes.column}>
              {renderSearch()}
            </div>
          </div>
          <div className={classes.listContainer}>
            {renderPlayerList()}
          </div>
        </div>
      </AdminContent>
    </>
  )
}

export default observer(Settings)
