import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  root: {
    background: Colors.white,
    paddingTop: '3rem' // = AdminHeader height
  }
}))

function AdminContent (props: any) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {props.children}
    </div>
  )
}

export default AdminContent
