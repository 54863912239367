import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useParams } from 'react-router-dom'
import get from 'lodash/get'
import moment from 'moment'
import { useStore } from '../../Models/RootStore'

import { Colors } from '../../Utils/theme'
import { AdminHeader, AdminContent, ScreenForm } from '../../Components'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '1rem 2rem'
  },
  title: {
    fontSize: '3.25rem',
    margin: 0,
    padding: 0,
    color: Colors.black,
    fontWeight: 800,
    marginBottom: '1rem'
  },
  screenId: {
    fontSize: '2rem',
    fontWeight: 800,
    marginBottom: '2rem'
  }
}))

const Screen = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { screenId } = useParams()
  const { screenStore } = useStore()

  const [title, setTitle] = useState(null)
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [defaultSortField, setDefaultSortField] = useState(null)
  const [visibleFields, setVisibleFields] = useState([])

  useEffect(() => {
    setTitle(get(screenStore.screen, 'title', null))
    setName(get(screenStore.screen, 'name', null))
    setDescription(get(screenStore.screen, 'description', null))
    setDefaultSortField(get(screenStore.screen, 'defaultSortField', null))
    setVisibleFields(get(screenStore.screen, 'visibleFields', null) || [])

    // Start date
    const newStartDate = get(screenStore.screen, 'startDate', null)
    if (newStartDate) {
      setStartDate(moment(newStartDate).format('YYYY-MM-DD[T]HH:mm'))
    } else {
      setStartDate(null)
    }

    // End date
    const newEndDate = get(screenStore.screen, 'endDate', null)
    if (newEndDate) {
      setEndDate(moment(newEndDate).format('YYYY-MM-DD[T]HH:mm'))
    } else {
      setEndDate(null)
    }

  }, [screenStore.screen])

  const handleSave = () => {
    screenStore.editScreen(Number(screenId), {
      name,
      title,
      description,
      defaultSortField,
      visibleFields,
      startDate: startDate ? moment(startDate).format() : null,
      endDate: endDate ? moment(endDate).format() : null
    })
  }


  useEffect(() => {
    screenStore.getScreen(Number(screenId))
  }, [])

  const renderForm = () => {
    return (
      <ScreenForm
        name={name}
        setName={setName}
        title={title}
        setTitle={setTitle}
        description={description}
        setDescription={setDescription}
        defaultSortField={defaultSortField}
        setDefaultSortField={setDefaultSortField}
        visibleFields={visibleFields}
        setVisibleFields={setVisibleFields}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        onSave={handleSave}
      />
    )
  }

  const renderScreenId = () => {
    if (screenId && Number(screenId) === get(screenStore.screen, 'id', null)) {
      return (
        <div className={classes.screenId}>
          {t('id')}: {screenId}
        </div>
      )
    }
    return null
  }

  return (
    <>
      <AdminHeader />
      <AdminContent>
        <div className={classes.content}>
          <h2 className={classes.title}>{t('screen_config')}</h2>
          <div>
            {renderScreenId()}
            {renderForm()}
          </div>
        </div>
      </AdminContent>
    </>
  )
}

export default observer(Screen)
