import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import { useParams } from 'react-router-dom'
import get from 'lodash/get'
import { useStore } from '../../Models/RootStore'

import { Colors } from '../../Utils/theme'
import { AdminHeader, AdminContent, PlayerForm } from '../../Components'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '1rem 2rem 4rem'
  },
  title: {
    fontSize: '3.25rem',
    margin: 0,
    padding: 0,
    color: Colors.black,
    fontWeight: 800,
    marginBottom: '1rem'
  },
  playerId: {
    fontSize: '2rem',
    fontWeight: 800,
    marginBottom: '1rem'
  },
  spacer: {
    height: '2rem'
  }
}))

const Player = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { playerId } = useParams()
  const { playerStore } = useStore()

  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [league, setLeague] = useState(null)
  const [gender, setGender] = useState(null)

  useEffect(() => {
    setFirstName(get(playerStore.player, 'firstName', null))
    setLastName(get(playerStore.player, 'lastName', null))
    setEmail(get(playerStore.player, 'email', null))
    setPhone(get(playerStore.player, 'phone', null))
    setLeague(get(playerStore.player, 'league', null))
    setGender(get(playerStore.player, 'gender', null))
  }, [playerStore.player])

  const handleSave = () => {
    playerStore.editPlayer(Number(playerId), {
      firstName,
      lastName,
      email,
      phone,
      league,
      gender
    })
  }

  useEffect(() => {
    playerStore.getPlayer(Number(playerId))
  }, [])

  const renderForm = () => {
    return (
      <PlayerForm
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        email={email}
        setEmail={setEmail}
        phone={phone}
        setPhone={setPhone}
        league={league}
        setLeague={setLeague}
        gender={gender}
        setGender={setGender}
        onSave={handleSave}
      />
    )
  }

  const renderPlayerId = () => {
    if (playerId && Number(playerId) === get(playerStore.player, 'id', null)) {
      return (
        <div>
          <div className={classes.playerId}>
            {t('id')}: {playerId}
          </div>
          <div className={classes.playerId}>
            {t('key')}: {get(playerStore.player, 'key', null)}
          </div>
          <div className={classes.spacer} />
        </div>
      )
    }
    return null
  }

  return (
    <>
      <AdminHeader />
      <AdminContent>
        <div className={classes.content}>
          <h2 className={classes.title}>{t('edit_player')}</h2>
          <div>
            {renderPlayerId()}
            {renderForm()}
          </div>
        </div>
      </AdminContent>
    </>
  )
}

export default observer(Player)
