import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { get } from 'lodash'
import { useStore } from '../../Models/RootStore'

import { Colors } from '../../Utils/theme'
import { AdminHeader, AdminContent } from '../../Components'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '1rem 2rem'
  },
  title: {
    fontSize: '3.25rem',
    margin: 0,
    padding: 0,
    color: Colors.black,
    fontWeight: 800
  },
  table: {
    margin: '1rem 0 2rem',
    borderSpacing: 0,
    borderCollapse: 'separate',
    border: `1px solid ${Colors.tableBorder}`,
    fontSize: '1.25rem',
    minWidth: '640px'
  },
  tableHead: {},
  tableRow: {
    '&:hover': {
      backgroundColor: Colors.tableHover
    }
  },
  cell: {
    padding: '.25rem .5rem',
    border: `1px solid ${Colors.tableBorder}`,
    wordBreak: 'break-word',
    minWidth: '4rem'
  },
  pagerRow: {
    float: 'left'
  },
  pageNumActive: {
    display: 'inline-block',
    padding: '.25rem .75rem',
    marginRight: '.5rem',
    marginBottom: '.5rem',
    fontWeight: 600,
    cursor: 'pointer',
    border: `2px solid ${Colors.tableBorder}`,
    backgroundColor: Colors.tableHover
  },
  pageNum: {
    display: 'inline-block',
    padding: '.25rem .75rem',
    marginRight: '.5rem',
    marginBottom: '.5rem',
    color: Colors.blue,
    cursor: 'pointer',
    border: `2px solid ${Colors.tableBorder}`,
  },
  clearFix: {
    display: 'table',
    clear: 'both'
  },
  statusVisible: {
    backgroundColor: Colors.green,
    color: Colors.white,
    padding: '.25rem .5rem',
    cursor: 'pointer'
  },
  statusHidden: {
    backgroundColor: Colors.red,
    color: Colors.white,
    padding: '.25rem .5rem',
    cursor: 'pointer'
  }
}))

const Records = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { recordStore, playerStore } = useStore()

  useEffect(() => {
    playerStore.getPlayers()
    recordStore.getRecords(1)
  }, [])

  const changePage = (pageNum) => {
    recordStore.getRecords(pageNum)
  }

  const getPlayerName = (playerId) => {
    if (!playerId) return ''
    const match = (get(playerStore, 'players', []) || []).find(p => p.id === playerId)
    if (match) {
      return `${match.firstName || ''} ${match.lastName || ''}`.trim()
    }
    return ''
  }

  const renderStatusButton = (record) => {
    if (record.active) {
      return (
        <div className={classes.statusVisible} onClick={() => recordStore.editRecord(record.id, { active: false })}>
          {t('visible')}
        </div>
      )
    }
    return (
      <div className={classes.statusHidden} onClick={() => recordStore.editRecord(record.id, { active: true })}>
        {t('hidden')}
      </div>
    )
  }

  const renderRecord = (record) => {
    return (
      <tr key={record.id} className={classes.tableRow}>
        {[
          record.id,
          record.screenId,
          record.playerId,
          getPlayerName(record.playerId),
          record.speed,
          record.velocity,
          record.spin,
          //record.duration,
          //record.distance,
          //record.height,
          record.vibra,
          record.speedratio,
          moment(record.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          renderStatusButton(record)
        ].map((val, index) => <td key={index} className={classes.cell}>{val}</td>)}
      </tr>
    )
  }

  const renderRecords = () => {
    return (
      <table className={classes.table}>
        <tr className={classes.tableHead}>
          {[
            t('id'),
            t('screen_id'),
            t('player_id'),
            t('name'),
            t('speed'),
            t('velocity'),
            t('spin'),
            //t('duration'),
            //t('distance'),
            //t('height'),
            t('vibra'),
            t('speedratio'),
            t('created_at'),
            t('status')
          ].map((val, index) => (
            <td key={index} className={classes.cell}>{val}</td>
          ))}
        </tr>
        {get(recordStore.records, 'items', []).map(record => {
          return renderRecord(record)
        })}
      </table>
    )
  }

  const renderPager = () => {
    const currentPage = get(recordStore.records, 'page')

    if (currentPage) {
      const numPages = recordStore.records.pages
      const pageNums = Array(numPages).fill(null).map((x, index) => {
        return index + 1
      })

      return (
        <div>
          <div className={classes.pagerRow}>
            {pageNums.map(num => {
              return (
                <div className={num === currentPage ? classes.pageNumActive : classes.pageNum} onClick={() => changePage(num)}>
                  {num}
                </div>
              )
            })}
          </div>
          <div className={classes.clearFix} />
        </div>
      )
    }
    return null
  }

  return (
    <>
      <AdminHeader />
      <AdminContent>
        <div className={classes.content}>
          <h2 className={classes.title}>{t('records')} ({get(recordStore.records, 'total', 0)})</h2>
          <div>
            {renderPager()}
            {renderRecords()}
            {renderPager()}
          </div>
        </div>
      </AdminContent>
    </>
  )
}

export default observer(Records)
