import apisauce from 'apisauce'

const API_URLS = {
  Local: 'http://127.0.0.1:1337/',
  Stage: 'https://gameproofer-api.stage.geniem.io/',
  Production: 'https://europeanopen-api.gameproofer.com/'
}

let currentApi = API_URLS.Production
if (window.location.hostname.includes('.stage.geniem.io')) currentApi = API_URLS.Stage
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('127.0.0.1')) currentApi = API_URLS.Local

// const auth = (token) => ({ headers: { Authorization: `Bearer ${token}` } })

const create = (baseURL = currentApi) => {
  const api = apisauce.create({
    withCredentials: true,
    baseURL,
    timeout: 20000
  })

  return {
    // Public endpoints
    registerPlayer: (data) => api.post('player', data),
    login: (data) => api.post('auth/login', data),

    getScreenDisplay: (screenId, sortField, gender, league) => api.get(`screen/display/${screenId}`, { sortField, gender, league }),
    getPlayerDisplay: (playerKey, sortField) => api.get(`screen/player/${playerKey}`, { sortField }),

    // Auth endpoints
    logout: () => api.put('auth/logout'),
    getMe: () => api.get('auth/me'),

    getRecordsPage: (page) => api.get(`record/page/${page}`),
    editRecord: (recordId, params) => api.put(`record/${recordId}`, params),

    getPlayers: () => api.get('player'),
    getPlayer: (playerId) => api.get(`player/${playerId}`),
    editPlayer: (playerId, data) => api.put(`player/${playerId}`, data),

    getScreens: () => api.get('screen'),
    getScreen: (screenId) => api.get(`screen/${screenId}`),
    editScreen: (screenId, data) => api.put(`screen/${screenId}`, data),

    getStatus: () => api.get('record/status'),
    setStatus: (data) => api.put('record/status', data),
    getLatestRecord: () => api.get('record/latest'),

    // Config
    getConfigs: () => api.get('config'),
    updateConfig: (key, data) => api.put(`config/${key}`, data)
  }
}

const Api = create()

export default Api
