import { createTheme, adaptV4Theme } from '@mui/material/styles'

export enum Colors {
  brandPrimary = '#CC0085',
  gold = '#FFCD1E',
  lightGold = '#ffd74a',
  navy = '#3E0843',
  black = '#000000',
  tableBorder = '#aaaaaa',
  tableHover = '#e8e8e8',
  lightText = '#9E83A1',
  lightGrey = '#FDF2FD',
  tableRow = '#E5E5E5',
  grey = '#C1C1C1',
  white = '#FFFFFF',
  green = '#3EB973',
  red = '#FF1744',
  blue = '#00417D',
  inputText = '#343434',
  border = '#CCCCCC',
  content = '#343434',

  black90 = 'rgba(0, 0, 0, .9)',
  black80 = 'rgba(0, 0, 0, .8)',
  black70 = 'rgba(0, 0, 0, .7)',
  black60 = 'rgba(0, 0, 0, .6)',
  black50 = 'rgba(0, 0, 0, .5)',
  black40 = 'rgba(0, 0, 0, .4)',
  black30 = 'rgba(0, 0, 0, .3)',
  black20 = 'rgba(0, 0, 0, .2)',
  black10 = 'rgba(0, 0, 0, .1)',
  black05 = 'rgba(0, 0, 0, .05)',
}

// A custom theme for this app
const Theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: Colors.brandPrimary
    },
    secondary: {
      main: Colors.brandPrimary
    },
    error: {
      main: Colors.red
    },
    background: {
      default: Colors.white
    }
  }
}))

export default Theme
