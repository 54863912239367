import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { useStore } from '../../Models/RootStore'

import { Colors } from '../../Utils/theme'
import { AdminHeader, AdminContent } from '../../Components'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '1rem 2rem'
  },
  title: {
    fontSize: '3.25rem',
    margin: 0,
    padding: 0,
    color: Colors.black,
    fontWeight: 800
  },
  table: {
    margin: '1rem 0 2rem',
    borderSpacing: 0,
    borderCollapse: 'separate',
    border: `1px solid ${Colors.tableBorder}`,
    fontSize: '1.25rem'
  },
  tableHead: {},
  tableRow: {
    '&:hover': {
      backgroundColor: Colors.tableHover
    }
  },
  cell: {
    padding: '.25rem .5rem',
    border: `1px solid ${Colors.tableBorder}`
  }
}))

const Screens = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { screenStore } = useStore()

  useEffect(() => {
    screenStore.getScreens()
  }, [])

  const renderScreen = (screen) => {
    return (
      <tr key={screen.id} className={classes.tableRow}>
        {[
          screen.id,
          screen.title,
          screen.name,
          screen.description,
          screen.startDate ? moment(screen.startDate).format('DD.MM.YYYY HH:mm') : null,
          screen.endDate ? moment(screen.endDate).format('DD.MM.YYYY HH:mm') : null,
          (<a target='_blank' rel='noreferrer' href={`/scoreboard/${screen.id}`}>{t('open_scoreboard')}</a>),
          (<Link to={`/screen/${screen.id}`}>{t('edit')}</Link>)
        ].map((val, index) => <td key={index} className={classes.cell}>{val}</td>)}
      </tr>
    )
  }

  const renderScreens = () => {
    return (
      <table className={classes.table}>
        <tr className={classes.tableHead}>
          {[
            t('id'),
            t('title'),
            t('name'),
            t('description'),
            t('start_date'),
            t('end_date'),
            t('scoreboard'),
            t('edit')
          ].map((val, index) => (
            <td key={index} className={classes.cell}>{val}</td>
          ))}
        </tr>
        {screenStore.screens.map(screen => {
          return renderScreen(screen)
        })}
      </table>
    )
  }

  return (
    <>
      <AdminHeader />
      <AdminContent>
        <div className={classes.content}>
          <h2 className={classes.title}>{t('screens')}</h2>
          <div>
            {renderScreens()}
          </div>
        </div>
      </AdminContent>
    </>
  )
}

export default observer(Screens)
