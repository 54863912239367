import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { Checkbox } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
    color: Colors.white
  },
  container: {
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    background: 'linear-gradient(rgba(76, 1, 80, .45), rgba(16,0,11, .45))',
    borderRadius: '0.3125rem',
    border: '1px solid rgba(255, 255, 255, .4)',
    fontWeight: 600,
    letterSpacing: '.1rem',
    paddingRight: '1rem',
    marginBottom: '.5rem',
    marginRight: '.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      paddingRight: '.25rem'
    }
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 .2rem 0 .4rem',
    backgroundColor: Colors.navy,
    minWidth: '3.5rem',
    borderTopLeftRadius: '.3125rem',
    borderBottomLeftRadius: '.3125rem',
    color: Colors.gold,
    fontSize: '1.25rem',
    [theme.breakpoints.down('md')]: {
      padding: '.5rem 0 .5rem 0',
    }
  },
  screenName: {
    flex: 1,
    color: Colors.white,
    position: 'relative',
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    wordBreak: 'break-all',
    minWidth: '5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      minWidth: '4rem',
      fontSize: '.85rem',
      padding: '.25rem .25rem .25rem .5rem'
    }
  }
}))


function MobileScreenRow (props: any) {
  const classes = useStyles()

  const renderCheckbox = () => {
    return (
      <Checkbox
        checked={props.selected}
        onChange={props.toggleSelected}
      />
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.checkboxContainer}>
          {renderCheckbox()}
        </div>
        <div className={classes.screenName}>
          {get(props.screen, 'name', null)}
        </div>
      </div>
    </div>
  )
}

export default MobileScreenRow
