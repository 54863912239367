import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    background: 'linear-gradient(rgba(76, 1, 80, .45), rgba(16,0,11, .45))',
    borderRadius: '0.3125rem',
    border: '1px solid rgba(255, 255, 255, .4)',
    fontWeight: 600,
    letterSpacing: '.1rem',
    paddingRight: '2rem',
    marginBottom: '.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      paddingRight: '0.125rem',
      marginBottom: '.25rem'
    }
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem .2rem .5rem .4rem',
    backgroundColor: Colors.navy,
    minWidth: '3.5rem',
    borderTopLeftRadius: '.3125rem',
    borderBottomLeftRadius: '.3125rem',
    color: Colors.gold,
    fontSize: '1.25rem'
  },
  playerName: {
    color: Colors.white,
    position: 'relative',
    padding: '.5rem 1rem',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      padding: '.25rem .75rem',
    }
  },
  headingPlaceholder: {
    height: '2.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: '1.5rem'
    }
  },
  heading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-2.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontWeight: 600,
    color: Colors.white,
    fontSize: '1.25rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      top: '-1.5rem',
      fontSize: '.85rem',
      paddingLeft: '.75rem',
      paddingRight: '.75rem'
    }
  },
  headingLeft: {
    textAlign: 'left'
  },
  headingRight: {
    textAlign: 'right'
  },
  starIcon: {
    width: '1.5rem'
  }
}))


function PlayerRow (props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeadingPlaceholder = () => {
    if (!props.displayHeading) return null
    return <div className={classes.headingPlaceholder} />
  }
  const renderHeading = (field) => {
    if (!props.displayHeading) return null

    const textAlignClass = classes.headingLeft
    return (
      <div className={[classes.heading, textAlignClass].join(' ')}>
        {t(field)}
      </div>
    )
  }


  const renderCheckbox = () => {
    return (
      <Checkbox
        checked={props.selected}
        onChange={props.toggleSelected}
      />
    )
  }

  const getName = () => {
    const { item } = props
    return `${get(item, 'firstName') || ''} ${get(item, 'lastName') || ''}`.trim()
  }

  const getEmail = () => {
    const { item } = props
    return get(item, 'email')
  }

  return (
    <div className={classes.root}>
      {renderHeadingPlaceholder()}
      <div className={classes.container}>
        <div className={classes.checkboxContainer}>
          {renderCheckbox()}
        </div>
        <div className={classes.playerName}>
          {renderHeading('name')}
          {getName()}
        </div>
        <div className={classes.playerName}>
          {renderHeading('email')}
          {getEmail()}
        </div>
      </div>
    </div>
  )
}

export default PlayerRow
