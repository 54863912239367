import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class PlayerStore {
  rootStore
  registerOkAt = 0
  loading = null

  players = []
  player = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateRegisterOkAt = (registerOkAt) => { this.registerOkAt = registerOkAt }
  updateLoading = (loading) => { this.loading = loading }
  updatePlayers = (players) => { this.players = players }
  updatePlayer = (player) => { this.player = player }

  async registerPlayer(player) {
    this.updateLoading(true)
    const response: any = await Api.registerPlayer({
      ...player,
      originHostname: window?.location?.hostname
    })
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'player_registered_success')
    if (response.ok) {
      this.updateRegisterOkAt(Date.now())
    }
    this.updateLoading(false)
  }

  async getPlayers() {
    this.updateLoading(true)
    const response: any = await Api.getPlayers()
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.updatePlayers(response.data)
    }
    this.updateLoading(false)
  }

  async getPlayer(playerId) {
    this.updateLoading(true)
    const response: any = await Api.getPlayer(playerId)
    if (response.ok) {
      this.updatePlayer(response.data)
    }
    this.updateLoading(false)
  }

  async editPlayer(playerId, data) {
    this.updateLoading(true)
    const response: any = await Api.editPlayer(playerId, data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      this.getPlayer(playerId)
    }
    this.updateLoading(false)
  }
}
