import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { useStore } from '../../Models/RootStore'
import { Link } from 'react-router-dom'

import { Colors } from '../../Utils/theme'
import { AdminHeader, AdminContent } from '../../Components'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '1rem 2rem'
  },
  title: {
    fontSize: '3.25rem',
    margin: 0,
    padding: 0,
    color: Colors.black,
    fontWeight: 800
  },
  table: {
    margin: '1rem 0 2rem',
    borderSpacing: 0,
    borderCollapse: 'separate',
    border: `1px solid ${Colors.tableBorder}`,
    fontSize: '1.25rem',
    minWidth: '640px'
  },
  tableHead: {},
  tableRow: {
    '&:hover': {
      backgroundColor: Colors.tableHover
    }
  },
  cell: {
    padding: '.25rem .5rem',
    border: `1px solid ${Colors.tableBorder}`,
    wordBreak: 'break-word',
    minWidth: '4rem'
  },
  positive: {
    backgroundColor: Colors.green,
    color: Colors.white,
    display: 'inline-block',
    padding: '0 .5rem',
    borderRadius: '.25rem'
  },
  negative: {
    backgroundColor: Colors.grey,
    color: Colors.white,
    display: 'inline-block',
    padding: '0 .5rem',
    borderRadius: '.25rem'
  }
}))

const Players = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { playerStore } = useStore()

  useEffect(() => {
    playerStore.getPlayers()
  }, [])

  const renderPlayer = (player) => {
    return (
      <tr key={player.id} className={classes.tableRow}>
        {[
          player.id,
          player.firstName,
          player.lastName,
          player.email,
          player.phone,
          (player.league || player.gender) ? `${player.league} / ${player.gender}` : '',
          player.marketingEnabled ? (<div className={classes.positive}>{t('yes')}</div>) : (<div className={classes.negative}>{t('no')}</div>),
          moment(player.createdAt).format('YYYY-MM-DD HH:mm:ss'),
          (<Link to={`/player/${player.id}`}>{t('edit')}</Link>),
          (<a href={`/profile/${player.key}`} target='_blank' rel='noreferrer'>{t('open')}</a>),
        ].map((val, index) => <td key={index} className={classes.cell}>{val}</td>)}
      </tr>
    )
  }

  const renderPlayers = () => {
    return (
      <table className={classes.table}>
        <tr className={classes.tableHead}>
          {[
            t('id'),
            t('first_name'),
            t('last_name'),
            t('email'),
            t('phone'),
            `${t('series')} / ${t('gender')}`,
            t('marketing_enabled'),
            t('created_at'),
            t('edit'),
            t('records')
          ].map((val, index) => (
            <td key={index} className={classes.cell}>{val}</td>
          ))}
        </tr>
        {playerStore.players.map(player => {
          return renderPlayer(player)
        })}
      </table>
    )
  }

  return (
    <>
      <AdminHeader />
      <AdminContent>
        <div className={classes.content}>
          <h2 className={classes.title}>{t('players')} ({playerStore.players.length})</h2>
          <a href="/register" target="_blank">{t('add_new')}</a>
          <div>
            {renderPlayers()}
          </div>
        </div>
      </AdminContent>
    </>
  )
}

export default observer(Players)
