import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class SessionStore {
  rootStore

  token = null
  user = null
  loading = null
  isLoggedIn = false
  initialized = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateToken = (token) => { this.token = token }
  updateUser = (user) => { this.user = user }
  updateLoading = (loading) => { this.loading = loading }
  updateIsLoggedIn = (isLoggedIn) => { this.isLoggedIn = isLoggedIn }
  updateInitialized = (initialized) => { this.initialized = initialized }

  async login(username, password) {
    this.updateLoading(true)
    const response: any = await Api.login({ username, password })
    this.updateLoading(false)

    if (response.status === 401) {
      this.rootStore.appStore.setErrorOrSuccess(response, 'wrong_username_or_password', false)
    } else {
      // Network error or internal server error
      this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    }

    if (response.ok) {
      const user = response.data.user
      this.updateUser(user)
      this.updateIsLoggedIn(true)
    }
  }

  async logout() {
    this.updateLoading(true)
    const response = await Api.logout()
    this.updateLoading(false)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.updateIsLoggedIn(false)
      window.location.reload()
    }
  }

  async getMe() {
    this.updateLoading(true)
    const response = await Api.getMe()
    this.updateLoading(false)
    if (response.ok) {
      const user = response.data
      this.updateUser(user)
      this.updateIsLoggedIn(true)
    }
    this.updateInitialized(true)
  }
}
