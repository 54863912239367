import React from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import Logo from '../../Assets/Images/logo.png'

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '12rem',
    paddingBottom: '1.5rem',
    [theme.breakpoints.down('lg')]: {
      width: '8rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem'
    }
  },
  header: {
    padding: '1rem 3rem',
    fontSize: '1.125rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.brandPrimary,
    backgroundImage: 'url(/images/background.jpg)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      padding: '1rem 1.5rem',
      paddingRight: '.5rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      backgroundImage: 'url(/images/background-mobile.jpg)'
    }
  },
  logoLink: {
    textDecoration: 'none',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '8rem'
    }
  },
  headerNav: {
    marginTop: '1rem',
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      top: '1rem',
      right: 0,
      marginTop: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '-.25rem',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  navTitle: {
    fontSize: '1.125rem',
    color: Colors.white,
    paddingRight: '1rem',
    marginBottom: '.75rem',
    fontWeight: 600,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 0
    }
  },
  navContainer: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex'
  },
  navItem: {
    padding: 0,
    margin: '0 1rem 0 0'
  },
  navLink: {
    display: 'inline-block',
    background: 'rgba(0,0,0,.2)',
    padding: '.66rem 1.25rem',
    textDecoration: 'none',
    color: Colors.white,
    fontSize: '1.25rem',
    fontWeight: 700,
    borderRadius: 5,
    '&:hover': {
      background: 'rgba(0,0,0,.3)',
    }
  },
  contentContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem'
  },
  title: {
    margin: 0,
    padding: 0,
    marginBottom: '1rem',
    color: Colors.white,
    fontSize: '1.85rem'
  },
  content: {
    padding: 0,
    margin: 0,
    color: Colors.white
  }
}))

const RegisterHeader = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderScoreboardLinks = () => {
    return (
      <div className={classes.headerNav}>
        <div className={classes.navTitle}>
          {t('scoreboards')}
        </div>
        <ul className={classes.navContainer}>
          <li className={classes.navItem}>
            <a href='/demo' className={classes.navLink}>{t('demo')}</a>
          </li>
          <li className={classes.navItem}>
            <a href='/show' className={classes.navLink}>{t('show')}</a>
          </li>
        </ul>
      </div>
    )
  }

  // Note: use a links instead of Link to prevent multiple pollings in scoreboards
  return (
    <div className={classes.header}>
      <a href="https://gameproofer.com" className={classes.logoLink}>
        <img src={Logo} className={classes.logo} />
      </a>
      <div className={classes.contentContainer}>
        <h2 className={classes.title}>
          {props.title}
        </h2>
        <p className={classes.content}>
          {props.content}
        </p>
      </div>
      {/* renderScoreboardLinks() */}
    </div>
  )
}

export default RegisterHeader
