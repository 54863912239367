import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Input, Button, Select, Checkbox } from '../'
import { RECORD_FIELD_OPTIONS } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '4rem'
  },
  title: {
    fontWeight: 800,
    fontSize: '1.5rem',
    marginBottom: '1rem'
  },
  button: {
    marginTop: '1rem',
    minWidth: '12rem'
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '24rem'
  },
  label: {
    marginBottom: '.5rem'
  },
  clearButton: {
    marginLeft: '1rem',
    marginTop: '.5rem'
  },
  checkboxRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  checkboxLabel: {
    marginTop: '.75rem',
    marginRight: '1rem'
  },
  description: {
    fontSize: '1.125rem'
  }
}))


function ScreenForm (props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getFieldOptions = () => {
    return RECORD_FIELD_OPTIONS.map(key => {
      return {
        label: t(key),
        value: key
      }
    })
  }

  const toggleVisibleField = (key) => {
    if ((props.visibleFields || []).includes(key)) {
      // Remove field
      props.setVisibleFields(props.visibleFields.filter(val => val !== key))
    } else {
      // Add field
      props.setVisibleFields([...(props.visibleFields || []), key])
    }
  }

  const renderSelectFields = () => {
    const fields = getFieldOptions()

    return fields.map(field => {
      const selectedFields = props.visibleFields || []
      const isSelected = selectedFields.includes(field.value)

      return (
        <div key={field.value} className={classes.checkboxRow}>
          <Checkbox
            checked={isSelected}
            onChange={() => toggleVisibleField(field.value)}
          />
          <div className={classes.checkboxLabel}>{field.label}</div>
        </div>
      )
    })
  }

  return (
    <div className={classes.root}>
      <Input
        label={t('title')}
        value={props.title}
        onChange={props.setTitle}
      />
      <Input
        label={t('name')}
        value={props.name}
        onChange={props.setName}
      />
      <Input
        label={t('description')}
        value={props.description}
        onChange={props.setDescription}
      />

      <div>
        <h3 className={classes.title}>{t('field_settings')}</h3>
        <br />
        <Select
          label={t('default_sort_field')}
          options={getFieldOptions()}
          value={props.defaultSortField}
          onChange={props.setDefaultSortField}
        />
        <div>
          <p className={classes.description}>{t('fields_to_display')}</p>
          {renderSelectFields()}
        </div>
      </div>

      <div>
        <h3 className={classes.title}>{t('date_time_ranges')}</h3>
        <p className={classes.description}>
          {t('screen_start_date_end_date_guide')}
        </p>
        <br />
      </div>

      <div className={classes.dateRow}>
        <div>
          <div className={classes.label}>{t('start_date')}</div>
          <Input
            value={props.startDate}
            onChange={props.setStartDate}
            type='datetime-local'
          />
        </div>
        <Button
          text={t('clear')}
          onClick={() => props.setStartDate(null)}
          buttonStyle={classes.clearButton}
        />
      </div>

      <div className={classes.dateRow}>
        <div>
          <div className={classes.label}>{t('end_date')}</div>
          <Input
            value={props.endDate}
            onChange={props.setEndDate}
            type='datetime-local'
          />
        </div>
        <Button
          text={t('clear')}
          onClick={() => props.setEndDate(null)}
          buttonStyle={classes.clearButton}
        />
      </div>

      <Button
        text={t('save')}
        onClick={props.onSave}
        buttonStyle={classes.button}
      />
    </div>
  )
}

export default ScreenForm
