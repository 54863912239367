import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    color: Colors.brandPrimary,
    fontSize: '2.5rem'
  }
}))

export default function Title(props) {
  const classes = useStyles()

  return (
    <h2 className={classes.mainTitle}>{props.title}</h2>
  )
}
