import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useStore } from './Models/RootStore'
import AppRoutes from './Containers'
import { observer } from 'mobx-react'

const App = () => {
  const { sessionStore }: any = useStore()

  useEffect(() => {
    // Detect www. in url and redirect without it
    if (window.location.hostname.indexOf('www.') === 0) {
      window.location = window.location.href.replace('www.', '')
    }

    sessionStore.getMe()
  }, [])

  const renderRouter = () => {
    if (!sessionStore.initialized) return null

    return <AppRoutes admin={sessionStore.isLoggedIn} />
  }

  return (
    <BrowserRouter>
      {renderRouter()}
    </BrowserRouter>
  )
}

export default observer(App)
