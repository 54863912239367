import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import StarIcon from '../../Assets/Icons/star.svg'
import { formatNumber } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    color: Colors.white
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    background: 'linear-gradient(rgba(76, 1, 80, .45), rgba(16,0,11, .45))',
    boxShadow: '0 0 2px rgba(255, 255, 255, .5)',
    borderRadius: '0.3125rem',
    border: '1px solid rgba(255, 255, 255, .4)',
    fontWeight: 600,
    letterSpacing: '.1rem',
    paddingRight: '2rem',
    marginBottom: '.5rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      letterSpacing: -0.125,
      paddingRight: '.25rem',
      marginBottom: '.125rem',
    }
  },
  rank: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    backgroundColor: Colors.navy,
    minWidth: '3.5rem',
    borderTopLeftRadius: '.3125rem',
    borderBottomLeftRadius: '.3125rem',
    color: Colors.gold,
    fontSize: '1.25rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      padding: '.5rem',
      minWidth: '1.875rem',
      fontSize: '.85rem'
    }
  },
  playerName: {
    position: 'relative',
    padding: '1rem',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '.85rem',
      padding: '.25rem'
    }
  },
  playerNameText: {
    wordBreak: 'break-all',
    textOverflow: 'hidden',
    overflow: 'hidden',
    maxHeight: '1.5rem',
    lineHeight: 1
  },
  metric: {
    position: 'relative',
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '9.5rem',
    fontSize: '1.25rem',

    // Table styles
    [theme.breakpoints.down('md')]: {
      padding: '.25rem',
      minWidth: '6rem',
      fontSize: '.85rem'
    },
    // Mobile styles
    [theme.breakpoints.down('sm')]: {
      padding: '.25rem',
      minWidth: '4rem',
      fontSize: '.85rem'
    }
  },
  highlight: {
    color: Colors.gold
  },
  headingPlaceholder: {
    height: '2.5rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: '1.5rem'
    }
  },
  clickable: {
    cursor: 'pointer'
  },
  heading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-2.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontWeight: 600,
    //color: Colors.white,
    fontSize: '1.25rem',
    whiteSpace: 'nowrap',

    display: 'block',
    //textOverflow: 'ellipsis',
    //overflow: 'hidden',

    // Tablet styles
    [theme.breakpoints.down('md')]: {
      //textOverflow: 'ellipsis',
      overflow: 'hidden',
      top: '-1.5rem',
      fontSize: '.75rem',
      paddingLeft: '.125rem',
      paddingRight: '.125rem'
    },
    [theme.breakpoints.down('sm')]: {
      //textOverflow: 'ellipsis',
      overflow: 'hidden',
      top: '-1.5rem',
      fontSize: '.6666rem',
      paddingLeft: '.125rem',
      paddingRight: '.125rem'
    },
  },
  headingLeft: {
    textAlign: 'left'
  },
  headingRight: {
    textAlign: 'center'
  },
  starIcon: {
    width: '1.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      width: '.85rem',
      paddingTop: '.25rem',
      paddingBottom: '.25rem'
    }
  },
  playerLink: {
    textDecoration: 'none',
    color: Colors.white,
    '&:hover': {
      textDecoration: 'underline',
      opacity: 0.9
    }
  }
}))


function ScoreRow (props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  const getPlayerName = () => {
    const playerName = `${get(props.item, 'player.firstName') || ''} ${get(props.item, 'player.lastName') || ''}`.trim()
    if (props.link && get(props.item, 'player.key')) {
      // Note: We use <a> instead of <Link> to force page change that removes polling
      return (
        <a href={`/profile/${get(props.item, 'player.key')}`} className={classes.playerLink}>
          {playerName}
        </a>
      )
    }
    return <span>{playerName}</span>
  }

  const isVisible = (key) => {
    if (props.showAllFields) return true

    if ((props.visibleFields || []).includes(key)) {
      return true
    }
    return false
  }

  const renderMetric = (key) => {
    if (!isVisible(key)) return null

    const sourceValue = get(props.item, key, null)
    let value = get(props.item, 'waiting') ? t('waiting') : ''

    const imperialPrefix = props.imperial ? 'imperial_' : ''

    if (sourceValue) {
      value = `${formatNumber(sourceValue, key, props.imperial)} ${t(`${imperialPrefix}unit_${key}`)}`
    }

    // If records are sorted by current sort field, use highlight class
    const isHighlight = props.currentSortField && props.currentSortField === key
    const highlightClass = isHighlight ? classes.highlight : ''

    const onHeadingClick = props.setSort ? () => props.setSort(key) : null

    return (
      <div className={[classes.metric, highlightClass].join(' ')}>
        {renderHeading(key, onHeadingClick)}
        {value}
      </div>
    )
  }

  const renderHeadingPlaceholder = () => {
    if (!props.displayHeading) return null
    return <div className={classes.headingPlaceholder} />
  }

  const renderHeading = (field, onPress = null) => {
    if (!props.displayHeading) return null

    const textAlignClass = field === 'name' ? classes.headingLeft : classes.headingRight

    if (field === 'name' && props.star) field = 'on_the_tee'

    // If records are sorted by current sort field, use highlight class
    const isHighlight = props.currentSortField && props.currentSortField === field
    const highlightClass = isHighlight ? classes.highlight : ''
    const clickableClass = (onPress) ? classes.clickable : ''

    return (
      <div
        className={[classes.heading, textAlignClass, highlightClass, clickableClass].join(' ')}
        onClick={onPress ? onPress : undefined}
      >
        {t(field)}
      </div>
    )
  }

  // Render rank or star
  const renderRank = () => {
    if (props.star) {
      return <img src={StarIcon} className={classes.starIcon} />
    }

    return props.rank ? `${props.rank}.` : ''
  }

  const getAnimationClasses = () => {
    // New record received animation class
    if (props.star) {
      return 'animate__animated animate__fast animate__flipInX'
    }
    return ''
  }

  return (
    <div className={classes.root}>
      {renderHeadingPlaceholder()}
      <div className={[classes.container, getAnimationClasses()].join(' ').trim()}>
        <div className={classes.rank}>
          {renderRank()}
        </div>
        <div className={classes.playerName}>
          {renderHeading('name')}
          <div className={classes.playerNameText}>
            {getPlayerName()}
          </div>
        </div>
        {renderMetric('speed')}
        {renderMetric('velocity')}
        {renderMetric('speedratio')}
        {renderMetric('spin')}
        {/* renderMetric('distance') */}
        {/* renderMetric('duration') */}
        {/* renderMetric('height') */}
        {renderMetric('vibra')}
      </div>
    </div>
  )
}

export default ScoreRow
