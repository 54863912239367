import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: '1.5rem'
  },
  item: {
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText
  },
  label: {
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black70,
    display: 'flex',
    alignItems: 'center'
  },
  focusedLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black
  },
  shrunkLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black
  }
}))

const STYLES = {
  select: {
    minWidth: '4.125rem',
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.lightGrey,
    borderRadius: '0.3125rem',
    '.MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${Colors.border} !important`
    },
    '& > div': {
      padding: '0rem 0.875rem !important'
    },
    '&:focus': {
      // backgroundColor: Colors.white
    }
  },
  selectDisabled: {
    height: '3.125rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '1rem',
    color: Colors.inputText,
    backgroundColor: Colors.lightGrey,
    borderRadius: '0.3125rem',
    '& > div': {
      padding: '0rem 0.875rem !important'
    }
  }
}

export default function BaseSelect(props) {
  const { label, placeholder, value, options, error, containerStyle, style, disabled } = props

  const classes = useStyles()

  const getSelectStyle = () => {
    if (disabled) {
      return style ? { ...STYLES.selectDisabled, ...style } : STYLES.selectDisabled
    }
    return style ? { ...STYLES.select, ...style } : STYLES.select
  }

  const onChange = (evt) => {
    props.onChange(evt.target.value)
  }

  const renderHelperText = () => {
    if (props.helperText) {
      return <FormHelperText>{props.helperText}</FormHelperText>
    }
    return null
  }

  return (
    <FormControl variant='outlined' className={classes.container} sx={containerStyle}>
      <InputLabel
        id='select-label'
        classes={{ root: classes.label, focused: classes.focusedLabel, shrink: classes.shrunkLabel }}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        labelId='select-label'
        id='select-outlined'
        value={value}
        onChange={onChange}
        label={label}
        sx={getSelectStyle()}
        disabled={disabled}
        error={error}
        placeholder={placeholder}
        variant='outlined'
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} classes={{ root: classes.item }}>
            {option.label || option.labelFi}
          </MenuItem>
        ))}
      </Select>
      {renderHelperText()}
    </FormControl>
  )
}
