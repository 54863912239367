import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '../'
import { formatNumber } from '../../Constants'
import ClearIcon from '../../Assets/Icons/clear.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    color: Colors.white
  },
  container: {
    lineHeight: 1,
    display: 'flex',
    flexDirection: 'row',
    background: 'linear-gradient(rgba(76, 1, 80, .45), rgba(16,0,11, .45))',
    borderRadius: '0.3125rem',
    border: '1px solid rgba(255, 255, 255, .4)',
    fontWeight: 600,
    letterSpacing: '.1rem',
    paddingRight: '1rem',
    marginBottom: '.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      paddingRight: '.25rem',
      letterSpacing: 0
    }
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '.5rem .2rem .5rem .4rem',
    backgroundColor: Colors.navy,
    minWidth: '3.5rem',
    borderTopLeftRadius: '.3125rem',
    borderBottomLeftRadius: '.3125rem',
    color: Colors.gold,
    fontSize: '1.25rem',
    [theme.breakpoints.down('md')]: {
      // Hide on mobile, we use MobileScreenRow component instead...
      display: 'none'
    }
  },
  playerName: {
    flex: 1,
    color: Colors.white,
    position: 'relative',
    padding: '.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    wordBreak: 'break-all',
    minWidth: '5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      minHeight: '3.5rem',
      minWidth: '4rem',
      fontSize: '.85rem',
      padding: '.25rem .25rem .25rem .5rem'
    }
  },
  metric: {
    position: 'relative',
    padding: '.5rem .25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: '6rem',
    fontSize: '1rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      minWidth: '4.5rem',
      fontSize: '.85rem',
      padding: '.25rem .2rem'
    }
  },
  headingPlaceholder: {
    height: '2.5rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: '1.5rem'
    }
  },
  heading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-2rem',
    paddingLeft: '.25rem',
    paddingRight: '.25rem',
    fontWeight: 600,
    color: Colors.white,
    fontSize: '.875rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '.85rem',
      top: '-1.5rem',
      paddingLeft: '.0125rem',
      paddingRight: '.0125rem',
    }
  },
  headingLeft: {
    textAlign: 'left'
  },
  headingRight: {
    textAlign: 'right'
  },
  clearActivePlayer: {
    marginTop: '.25rem',
    padding: '.25rem',
    cursor: 'pointer',
    opacity: 0.6,
    '&:hover': {
      opacity: 1
    }
  },
  clearIcon: {
    height: '1rem'
  }
}))


function ScreenStatusRow (props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeadingPlaceholder = () => {
    return <div className={classes.headingPlaceholder} />
  }

  const renderHeading = (field) => {
    const textAlignClass = field === 'name' ? classes.headingLeft : classes.headingRight
    return (
      <div className={[classes.heading, textAlignClass].join(' ')}>
        {t(field)}
      </div>
    )
  }

  const renderCheckbox = () => {
    return (
      <Checkbox
        checked={props.selected}
        onChange={props.toggleSelected}
      />
    )
  }

  const renderScreenName = () => {
    return (
      <div className={[classes.heading, classes.headingLeft].join(' ')}>
        {get(props.screen, 'name', null)}
      </div>
    )
  }

  const getActivePlayerName = () => {
    const { activePlayer } = props
    if (!activePlayer) return t('not_active')

    return `${get(activePlayer, 'firstName') || ''} ${get(activePlayer, 'lastName') || ''}`.trim()
  }

  const renderMetric = (key) => {
    const metricValue = get(props.latestRecord, key, null)
    let value = props.waiting ? t('waiting') : '-'

    const imperialPrefix = props.imperial ? 'imperial_' : ''

    if (metricValue) {
      value = `${formatNumber(metricValue, key, props.imperial)} ${t(`${imperialPrefix}unit_${key}`)}`
    }
    return (
      <div className={classes.metric}>
        {renderHeading(key)}
        {value}
      </div>
    )
  }

  const renderClearActivePlayerButton = () => {
    if (!props.activePlayer) return null

    return (
      <div className={classes.clearActivePlayer} onClick={props.clearActivePlayer}>
        <img src={ClearIcon} className={classes.clearIcon} />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {renderHeadingPlaceholder()}
      <div className={classes.container}>
        <div className={classes.checkboxContainer}>
          {renderCheckbox()}
        </div>
        <div className={classes.playerName}>
          {renderScreenName()}
          {getActivePlayerName()}
          {renderClearActivePlayerButton()}
        </div>
        {renderMetric('speed')}
        {renderMetric('velocity')}
        {renderMetric('speedratio')}
        {renderMetric('spin')}
        {/* renderMetric('distance') */}
        {/* renderMetric('duration') */}
        {/* renderMetric('height') */}
        {renderMetric('vibra')}
      </div>
    </div>
  )
}

export default ScreenStatusRow
