import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { Input, Button, Checkbox } from '../'
import { Colors } from '../../Utils/theme'
import { Gender, League } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 800,
    fontSize: '1.5rem',
    marginBottom: '2rem'
  },
  button: {
    minWidth: '12rem',
    marginTop: '2rem'
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '24rem'
  },
  label: {
    marginBottom: '.5rem'
  },
  clearButton: {
    marginLeft: '1rem',
    marginTop: '.5rem'
  },
  radioRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioItem: {
    marginLeft: '-.5rem',
    marginRight: '2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  radioLabel: {
    paddingTop: '.33rem',
    fontSize: '1.25rem',
    color: Colors.lightText,
    fontWeight: 300
  },
  sectionTitle: {
    paddingTop: '.33rem',
    fontSize: '1.25rem',
    color: Colors.content,
    fontWeight: 300
  }
}))

function PlayerForm (props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <Input
        label={t('first_name')}
        value={props.firstName}
        onChange={props.setFirstName}
      />
      <Input
        label={t('last_name')}
        value={props.lastName}
        onChange={props.setLastName}
      />
      <Input
        label={t('email')}
        value={props.email}
        onChange={props.setEmail}
      />
      <Input
        label={t('phone')}
        value={props.phone}
        onChange={props.setPhone}
      />

      <div className={classes.sectionTitle}>{t('series')}</div>
      <div className={classes.radioRow}>
        <div className={classes.radioItem}>
          <Checkbox
            checked={props.league === League.Amateur}
            onChange={() => props.setLeague(League.Amateur)}
            radio
          />
          <div className={classes.radioLabel}>{t('amateur')}</div>
        </div>
        <div className={classes.radioItem}>
          <Checkbox
            checked={props.league === League.Pro}
            onChange={() => props.setLeague(League.Pro)}
            radio
          />
          <div className={classes.radioLabel}>{t('pro')}</div>
        </div>
      </div>

      <div className={classes.sectionTitle}>{t('gender')}</div>
      <div className={classes.radioRow}>
        <div className={classes.radioItem}>
          <Checkbox
            checked={props.gender === Gender.Male}
            onChange={() => props.setGender(Gender.Male)}
            radio
          />
          <div className={classes.radioLabel}>{t('male')}</div>
        </div>
        <div className={classes.radioItem}>
          <Checkbox
            checked={props.gender === Gender.Female}
            onChange={() => props.setGender(Gender.Female)}
            radio
          />
          <div className={classes.radioLabel}>{t('female')}</div>
        </div>
      </div>

      <Button
        text={t('save')}
        onClick={props.onSave}
        buttonStyle={classes.button}
      />
    </div>
  )
}

export default PlayerForm
