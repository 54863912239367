import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { Input, Title, Button, Checkbox, RegisterHeader } from '../../Components'
import { Gender, League } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    }
  },
  content: {
    flex: 2,
    padding: '1rem 1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  formContainer: {
    width: '100%',
    maxWidth: '38rem',
    paddingBottom: '1.5rem'
  },
  registerButton: {
    width: '12rem'
  },
  marketingRow: {
    marginLeft: '-.5rem',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1rem'
  },
  marketingText: {
    paddingTop: '.33rem',
    fontSize: '1.25rem',
    color: Colors.lightText,
    fontWeight: 300
  },
  privacyContainer: {
    //marginLeft: '12rem',
    //position: 'absolute',
    display: 'flex',
    marginBottom: '2rem',
    //alignItems: 'center',
    //top: '1.25rem',
    //right: '1.5rem',
    flexDirection: 'row',
    textDecoration: 'none',
    opacity: 0.75,
    '&:hover': {
      opacity: 1
    }
  },
  privacyText: {
    color: Colors.brandPrimary,
    marginRight: '.65rem',
    fontSize: '1.125rem'
  },
  privacyInfo: {
    display: 'flex',
    height: '1.5rem',
    width: '1.5rem',
    minWidth: '1.5rem',
    maxWidth: '1.5rem',
    minHeight: '1.5rem',
    maxHeight: '1.5rem',
    borderRadius: '1.5rem',
    border: `1px solid ${Colors.brandPrimary}`,
    color: Colors.brandPrimary,
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    userSelect: 'none'
  },
  langContainer: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'rgba(0,0,0,.02)',
    border: '1px solid rgba(0,0,0,.3)',
    padding: '0 .25rem 0 .33rem',
    borderRadius: 5
  },
  langButton: {
    padding: '.25rem',
    marginRight: '.5rem',
    cursor: 'pointer',
    color: Colors.brandPrimary
  },
  langButtonActive: {
    padding: '.25rem',
    marginRight: '.5rem',
    cursor: 'pointer',
    color: Colors.brandPrimary,
    fontWeight: 600
  },
  langDivider: {
    width: '1px',
    marginRight: '.5rem',
    backgroundColor: 'rgba(0,0,0,.3)',
    height: '1.25rem'
  },
  formDescription: {
    color: Colors.content,
    margin: 0,
    fontSize: '1.125rem',
    marginTop: '-.5rem',
    padding: '0 0 1rem'
  },
  radioRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioItem: {
    marginLeft: '-.5rem',
    marginRight: '2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  radioLabel: {
    paddingTop: '.33rem',
    fontSize: '1.25rem',
    color: Colors.lightText,
    fontWeight: 300
  },
  sectionTitle: {
    paddingTop: '.33rem',
    fontSize: '1.25rem',
    color: Colors.content,
    fontWeight: 300
  }
}))

const Register = (props) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { playerStore, configStore } = useStore()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [marketingEnabled, setMarketingEnabled] = useState(false)
  const [league, setLeague] = useState(League.Amateur)
  const [gender, setGender] = useState(Gender.Male)

  useEffect(() => {
    configStore.getConfigs()
  }, [])

  const getRegisterTitle = () => {
    const lang = i18n.language
    if (lang === 'fi') {
      const match = configStore.configs.find(item => item.key === 'register_title_fi')
      if (match) {
        return match.value
      }
    }
    const match = configStore.configs.find(item => item.key === 'register_title_en')
    if (match) {
      return match.value
    }
    return ''
  }

  const getRegisterContent = () => {
    const lang = i18n.language
    if (lang === 'fi') {
      const match = configStore.configs.find(item => item.key === 'register_content_fi')
      if (match) {
        return match.value
      }
    }
    const match = configStore.configs.find(item => item.key === 'register_content_en')
    if (match) {
      return match.value
    }
    return ''
  }


  useEffect(() => {
    if (playerStore.registerOkAt && (playerStore.registerOkAt > Date.now() - 2000)) {
      setFirstName('')
      setLastName('')
      setEmail('')
      setPhone('')
      // setLeague(League.Amateur)
      // setGender(Gender.Male)
      setMarketingEnabled(false)
    }
  }, [playerStore.registerOkAt])

  const handleSetEmail = (val) => {
    setEmail(val.replace(/\s/g, ''))
  }

  const handleRegister = () => {
    const newPlayer = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      phone: phone.trim(),
      marketingEnabled,
      league,
      gender
    }
    playerStore.registerPlayer(newPlayer)
  }

  const isValidForm = () => {
    if (
      league &&
      gender &&
      firstName &&
      firstName.trim() &&
      lastName &&
      lastName.trim() &&
      email &&
      /^\S+@\S+\.\S+$/.test(email)
    ) {
      return true
    }

    return false
  }

  const renderForm = () => {
    return (
      <div className={classes.formContainer}>
        <Title title={t('sign_up')} />
        <p className={classes.formDescription}>{t('register_form_description')}</p>
        {renderPrivacy()}
        <Input
          label={`${t('first_name')} *`}
          value={firstName}
          onChange={setFirstName}
          maxLength={64}
        />
        <Input
          label={`${t('last_name')} *`}
          value={lastName}
          onChange={setLastName}
          maxLength={64}
        />
        <Input
          label={`${t('email')} *`}
          value={email}
          onChange={handleSetEmail}
          maxLength={128}
        />
        <Input
          label={t('phone')}
          value={phone}
          onChange={setPhone}
          maxLength={64}
        />

        <div className={classes.sectionTitle}>{t('series')}</div>
        <div className={classes.radioRow}>
          <div className={classes.radioItem}>
            <Checkbox
              checked={league === League.Amateur}
              onChange={() => setLeague(League.Amateur)}
              radio
            />
            <div className={classes.radioLabel}>{t('amateur')}</div>
          </div>
          <div className={classes.radioItem}>
            <Checkbox
              checked={league === League.Pro}
              onChange={() => setLeague(League.Pro)}
              radio
            />
            <div className={classes.radioLabel}>{t('pro')}</div>
          </div>
        </div>

        <div className={classes.sectionTitle}>{t('gender')}</div>
        <div className={classes.radioRow}>
          <div className={classes.radioItem}>
            <Checkbox
              checked={gender === Gender.Male}
              onChange={() => setGender(Gender.Male)}
              radio
            />
            <div className={classes.radioLabel}>{t('male')}</div>
          </div>
          <div className={classes.radioItem}>
            <Checkbox
              checked={gender === Gender.Female}
              onChange={() => setGender(Gender.Female)}
              radio
            />
            <div className={classes.radioLabel}>{t('female')}</div>
          </div>
        </div>

        <div className={classes.sectionTitle}>{t('marketing')}</div>
        <div className={classes.marketingRow}>
          <Checkbox
            checked={marketingEnabled}
            onChange={() => setMarketingEnabled(!marketingEnabled)}
          />
          <div className={classes.marketingText}>
            {t('i_want_to_receive_marketing_emails')}
          </div>
        </div>
        <Button
          text={t('send')}
          onClick={handleRegister}
          buttonStyle={classes.registerButton}
          disabled={!isValidForm()}
        />
      </div>
    )
  }

  const renderPrivacy = () => {
    return (
      <a href="https://gameproofer.com/GDPR/GameProofer-GDPR.pdf" target='_blank' rel='noreferrer' className={classes.privacyContainer}>
        <div className={classes.privacyText}>{t('click_here_for_our_privacy_policy')}</div>
        <div className={classes.privacyInfo}>i</div>
      </a>
    )
  }

  const renderLangSelect = () => {
    return (
      <div className={classes.langContainer}>
        <div className={i18n.language === 'en' ? classes.langButtonActive : classes.langButton} onClick={() => i18n.changeLanguage('en')}>EN</div>
        <div className={classes.langDivider} />
        <div className={i18n.language === 'fi' ? classes.langButtonActive : classes.langButton} onClick={() => i18n.changeLanguage('fi')}>FI</div>
      </div>

    )
  }

  return (
    <div className={classes.root}>
      <RegisterHeader
        title={getRegisterTitle()}
        content={getRegisterContent()}
      />
      <div className={classes.content}>
        {renderLangSelect()}
        {renderForm()}
      </div>
    </div>
  )
}

export default observer(Register)
