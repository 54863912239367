import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useStore } from '../../Models/RootStore'

const useStyles = makeStyles((theme) => ({
  root: {
    background: Colors.navy,
    color: Colors.white,
    display: 'flex',
    alignItems: 'center',
    height: '3rem',
    position: 'fixed',
    paddingLeft: '2rem',
    paddingRight: '1rem',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 3,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '.85rem',
      paddingRight: 0
    }
  },
  flexSpacer: {
    flex: 1
  },
  navItem: {
    marginRight: '1rem',
    padding: '.5rem',
    color: 'rgba(255, 255, 255, .8)',
    textDecoration: 'none',
    cursor: 'pointer',
    borderTop: '.125rem solid transparent',
    borderBottom: '.125rem solid transparent',
    '&:hover': {
      color: Colors.white,
      borderBottom: `.125rem solid ${Colors.white}`
    },
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
      padding: '.3rem .05rem'
    }
  }
}))


function AdminHeader (props: any) {
  const classes = useStyles()
  const { t } = useTranslation()

  const { sessionStore } = useStore()

  const handleLogout = () => {
    const sure = window.confirm(t('confirm_logout'))
    if (sure) {
      sessionStore.logout()
    }
  }

  const renderNavigation = () => {
    return [
      {
        name: t('main'),
        path: '/settings'
      },
      {
        name: t('records'),
        path: '/records'
      },
      {
        name: t('players'),
        path: '/players'
      },
      {
        name: t('screens'),
        path: '/screens'
      },
      {
        name: t('config'),
        path: '/config'
      }
    ].map((item, index) => {
      return (
        <Link
          key={index}
          to={item.path}
          className={classes.navItem}
        >
          {item.name}
        </Link>
      )
    })
  }

  return (
    <div className={classes.root}>
      {renderNavigation()}
      <div className={classes.flexSpacer} />
      <div className={classes.navItem} onClick={handleLogout}>
        {t('logout')}
      </div>
    </div>
  )
}

export default AdminHeader
