import { createContext, useContext } from 'react'
import AppStore from './AppStore'
import SessionStore from './SessionStore'
import PlayerStore from './PlayerStore'
import RecordStore from './RecordStore'
import ScreenStore from './ScreenStore'
import ConfigStore from './ConfigStore'

class RootStore {
  appStore
  sessionStore
  playerStore
  recordStore
  screenStore
  configStore

  constructor() {
    this.appStore = new AppStore(this)
    this.sessionStore = new SessionStore(this)
    this.playerStore = new PlayerStore(this)
    this.recordStore = new RecordStore(this)
    this.screenStore = new ScreenStore(this)
    this.configStore = new ConfigStore(this)
  }
}

export const createStore = () => {
  const rootStore = new RootStore()
  return rootStore
}

export const StoreContext = createContext({})
export const StoreProvider = StoreContext.Provider

export const useStore = () => useContext(StoreContext)
