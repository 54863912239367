import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class ScreenStore {
  rootStore
  loading = null
  display = null
  playerDisplay = null
  sortField = null
  gender = null
  league = null
  screens = []
  screen = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateDisplay = (display) => { this.display = display }
  updatePlayerDisplay = (playerDisplay) => { this.playerDisplay = playerDisplay }
  updateLoading = (loading) => { this.loading = loading }
  updateSortField = (sortField) => { this.sortField = sortField }
  updateScreens = (screens) => { this.screens = screens }
  updateScreen = (screen) => { this.screen = screen }
  updateGender = (gender) => { this.gender = gender }
  updateLeague = (league) => { this.league = league }

  async getScreens() {
    this.updateLoading(true)
    const response = await Api.getScreens()
    if (response.ok) {
      this.updateScreens(response.data)
    }
    this.updateLoading(false)
  }

  async getScreen(screenId) {
    this.updateLoading(true)
    const response: any = await Api.getScreen(screenId)
    if (response.ok) {
      this.updateScreen(response.data)
    }
    this.updateLoading(false)
  }

  async editScreen(screenId, data) {
    this.updateLoading(true)
    const response: any = await Api.editScreen(screenId, data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, 'info_updated')
    if (response.ok) {
      this.getScreen(screenId)
    }
    this.updateLoading(false)
  }

  async getDisplay(screenId) {
    this.updateLoading(true)
    const response: any = await Api.getScreenDisplay(screenId, this.sortField, this.gender, this.league)
    if (response.ok) {
      this.updateDisplay(response.data)
    }
    this.updateLoading(false)
  }

  async startGetDisplayPolling(screenId) {
    await this.getDisplay(screenId)
    // Poll with 3 sec interval
    setTimeout(() => this.startGetDisplayPolling(screenId), 2000)
  }

  // Player specific "display"
  async getPlayerDisplay(playerKey, sortField) {
    this.updateLoading(true)
    const response: any = await Api.getPlayerDisplay(playerKey, sortField)
    if (response.ok) {
      this.updatePlayerDisplay(response.data)
    }
    this.updateLoading(false)
  }

}
