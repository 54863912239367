import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import Checkbox from '@mui/material/Checkbox'
import { Colors } from '../../Utils/theme'
import CheckIcon from '../../Assets/Icons/check.svg'

const useStyles = makeStyles((theme) => ({
  container: {
    border: `2px solid ${Colors.border}`,
    height: '1.5625rem',
    width: '1.5625rem',
    borderRadius: '.25rem',
    borderWidth: '1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.lightGrey,
    marginRight: '0.25rem'
  },
  check: {
    height: '1rem',
    maxHeight: '1rem',
    maxWidth: '1rem'
  },
  radioContainer: {
    border: `1px solid ${Colors.border}`,
    height: '1.5625rem',
    width: '1.5625rem',
    borderRadius: '2rem',
    borderWidth: '1px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.lightGrey,
    marginRight: '0.25rem'
  },
  radio: {
    height: '.875rem',
    width: '.857rem',
    borderRadius: '1rem',
    backgroundColor: '#B817CC'
  }
}))

export default function CustomCheckbox(props) {
  const classes = useStyles()

  const renderUnchecked = () => {
    if (props.radio) return <div className={classes.radioContainer} />
    return <div className={classes.container} />
  }
  const renderChecked = () => {
    if (props.radio) {
      return (
        <div className={classes.radioContainer}>
          <div className={classes.radio} />
        </div>
      )
    }
    return (
      <div className={classes.container}>
        <img src={CheckIcon} className={classes.check} />
      </div>
    )
  }

  return (
    <Checkbox
      checkedIcon={renderChecked()}
      icon={renderUnchecked()}
      checked={props.checked}
      onChange={props.onChange}
    />
  )
}
