import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AppLayout } from '../Layouts'

// Public Routes
import Register from './Public/Register'
import Login from './Public/Login'
import Scoreboard from './Public/Scoreboard'
import Profile from './Public/Profile'

// Private routes
import Settings from './Private/Settings'
import Records from './Private/Records'
import Screens from './Private/Screens'
import Screen from './Private/Screen'
import Players from './Private/Players'
import Player from './Private/Player'
import Config from './Private/Config'

export default function AppRoutes(props) {
  if (!props.admin) {
    // Public routes
    return (
      <AppLayout>
        <Routes>
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
          <Route path='/scoreboard/:screenId' element={<Scoreboard />} />
          <Route path='/profile/:playerKey' element={<Profile />} />

          <Route path='/demo' element={<Navigate replace to='/scoreboard/1' />} />
          <Route path='/show' element={<Navigate replace to='/scoreboard/2' />} />

          <Route path='/settings' element={<Navigate replace to='/login' />} />
          <Route path='/admin' element={<Navigate replace to='/login' />} />
          <Route path='*' element={<Navigate replace to='/register' />} />
        </Routes>
      </AppLayout>
    )
  } else {
    // Private Admin routes
    return (
      <AppLayout>
        <Routes>
          <Route path='/register' element={<Register />} />
          <Route path='/scoreboard/:screenId' element={<Scoreboard />} />
          <Route path='/profile/:playerKey' element={<Profile />} />

          <Route path='/settings' element={<Settings />} />
          <Route path='/records' element={<Records />} />
          <Route path='/screens' element={<Screens />} />
          <Route path='/screen/:screenId' element={<Screen />} />
          <Route path='/players' element={<Players />} />
          <Route path='/player/:playerId' element={<Player />} />
          <Route path='/config' element={<Config />} />

          <Route path='/demo' element={<Navigate replace to='/scoreboard/1' />} />
          <Route path='/show' element={<Navigate replace to='/scoreboard/2' />} />

          <Route path='*' element={<Navigate replace to='/settings' />} />
        </Routes>
      </AppLayout>
    )
  }
}
