import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class RecordStore {
  rootStore
  loading = null
  configs = []

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateConfigs = (configs) => { this.configs = configs }
  updateLoading = (loading) => { this.loading = loading }

  async getConfigs() {
    this.updateLoading(true)
    const response: any = await Api.getConfigs()
    if (response.ok) {
      this.updateConfigs(response.data)
    }
    this.updateLoading(false)
  }

  async setConfigs(configs: any) {
    this.updateLoading(true)

    let failCount = 0
    let successCount = 0

    for (const conf of configs) {
      const response: any = await Api.updateConfig(conf.key, { value: conf.value })
      if (response.ok) {
        successCount = successCount + 1
      } else {
        failCount = failCount + 1
      }
    }
    this.rootStore.appStore.setErrorOrSuccess({ ok: failCount === 0 }, true, true)
    // Reload configs
    await this.getConfigs()

    this.updateLoading(false)
  }
}
