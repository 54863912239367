import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import { useStore } from '../../Models/RootStore'

import { Colors } from '../../Utils/theme'
import { AdminHeader, AdminContent, Button, Input, Checkbox } from '../../Components'
import { MeasurementSystem } from '../../Constants'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '1rem 2rem'
  },
  title: {
    fontSize: '3.25rem',
    margin: 0,
    padding: 0,
    color: Colors.black,
    fontWeight: 800,
    marginBottom: '1rem'
  },
  screenId: {
    fontSize: '2rem',
    fontWeight: 800,
    marginBottom: '2rem'
  },
  radioRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  radioItem: {
    marginLeft: '-.5rem',
    marginRight: '2rem',
    display: 'flex',
    flexDirection: 'row'
  },
  radioLabel: {
    paddingTop: '.33rem',
    fontSize: '1.25rem',
    color: Colors.lightText,
    fontWeight: 300
  },
  sectionTitle: {
    paddingTop: '.33rem',
    fontSize: '1.25rem',
    color: Colors.content,
    fontWeight: 300
  },
  footerSpacer: {
    height: '3rem'
  },
  button: {
    minWidth: '12rem'
  }
}))

const Config = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { configStore } = useStore()

  const [configs, setConfigs] = useState<any>([])

  useEffect(() => {
    configStore.getConfigs()
  }, [])

  useEffect(() => {
    setConfigs(configStore.configs)
  }, [configStore.configs])

  const updateConfig = (key, value) => {
    let didUpdate = false
    const newConfigs = configs.map((item) => {
      const currentItem: any = item
      if (currentItem.key === key) {
        didUpdate = true
        return {
          ...currentItem,
          value
        }
      }
      return currentItem
    })
    if (!didUpdate) {
      // Add new item if update was not made
      newConfigs.push({ key, value })
    }

    setConfigs(newConfigs)
  }

  const getConfigValue = (key) => {
    const config = configs.find(item => item.key === key)
    if (config) return config.value
    return ''
  }

  const handleSave = () => {
    configStore.setConfigs(configs.map(item => {
      return {
        key: item.key,
        value: item.value
      }
    }))
  }

  const renderConfigs = () => {
    const fields: any = [
      {
        key: 'register_title_en',
        type: 'text'
      },
      {
        key: 'register_content_en',
        type: 'textarea'
      },
      {
        key: 'register_title_fi',
        type: 'text'
      },
      {
        key: 'register_content_fi',
        type: 'textarea'
      }
    ]
    return fields.map(item => {
      return (
        <Input
          key={item.key}
          label={t(item.key)}
          value={getConfigValue(item.key)}
          multiline={item.type === 'textarea'}
          onChange={(value) => updateConfig(item.key, value)}
        />
      )
    })
  }

  const renderMeasurementSystem = () => {
    return (
      <div className={classes.radioRow}>
        <div className={classes.radioItem}>
          <Checkbox
            checked={getConfigValue('measurement_system') === MeasurementSystem.Metric}
            onChange={() => updateConfig('measurement_system', MeasurementSystem.Metric)}
            radio
          />
          <div className={classes.radioLabel}>{t('measurement_system_metric')}</div>
        </div>
        <div className={classes.radioItem}>
          <Checkbox
            checked={getConfigValue('measurement_system') === MeasurementSystem.Imperial}
            onChange={() => updateConfig('measurement_system', MeasurementSystem.Imperial)}
            radio
          />
          <div className={classes.radioLabel}>{t('measurement_system_imperial')}</div>
        </div>
      </div>
    )
  }

  return (
    <>
      <AdminHeader />
      <AdminContent>
        <div className={classes.content}>
          <h2 className={classes.title}>{t('config')}</h2>
          <div>
            <h3 className={classes.sectionTitle}>{t('measurement_system')}</h3>
            {renderMeasurementSystem()}
          </div>
          <div>
            <h2 className={classes.sectionTitle}>{t('register_page_contents')}</h2>
            {renderConfigs()}
          </div>
          <Button
            text={t('save')}
            onClick={handleSave}
            buttonStyle={classes.button}
          />
        </div>
        <div className={classes.footerSpacer} />
      </AdminContent>
    </>
  )
}

export default observer(Config)
