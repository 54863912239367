import { makeAutoObservable } from 'mobx'
import { Api } from '../Services'

export default class RecordStore {
  rootStore
  loading = null
  status = null
  latestRecord = null
  latestRecordLoading = null
  latestRecordPolling = false
  records = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  updateStatus = (status) => { this.status = status }
  updateLoading = (loading) => { this.loading = loading }
  updateLatestRecord = (latestRecord) => { this.latestRecord = latestRecord }
  updateLatestRecordLoading = (latestRecordLoading) => { this.latestRecordLoading = latestRecordLoading }
  updateLatestRecordPolling = (latestRecordPolling) => { this.latestRecordPolling = latestRecordPolling }
  updateRecords = (records) => { this.records = records }

  async getStatus() {
    this.updateLoading(true)
    const response: any = await Api.getStatus()
    if (response.ok) {
      this.updateStatus(response.data)
    }
    this.updateLoading(false)
  }

  async setStatus(data) {
    this.updateLoading(true)
    const response: any = await Api.setStatus(data)
    this.rootStore.appStore.setErrorOrSuccess(response, true, false)
    if (response.ok) {
      this.updateStatus(response.data)
    }
    this.updateLoading(false)
  }

  async getLatestRecord() {
    this.updateLatestRecordLoading(true)
    const response = await Api.getLatestRecord()
    if (response.ok) {
      this.updateLatestRecord(response.data)
    }
    this.updateLatestRecordLoading(false)
  }

  async getRecords(page) {
    const response = await Api.getRecordsPage(page)
    if (response.ok) {
      this.updateRecords(response.data)
    }
  }

  async editRecord(recordId, params) {
    const response = await Api.editRecord(recordId, params)
    if (response.ok) {
      if (this.records) {
        this.updateRecords({
          ...this.records,
          items: this.records.items.map(item => {
            if (item.id === recordId) {
              return response.data
            }
            return item
          })
        })
      }
    }
  }

  pollGetLatestRecord() {
    if (!this.latestRecordLoading) {
      this.getLatestRecord()
    }
    setTimeout(() => this.pollGetLatestRecord(), 2000)
  }

  startGetLatestRecordPolling() {
    // Make sure we dont spawn multiple pollers
    if (!this.latestRecordPolling) {
      this.updateLatestRecordPolling(true)
      this.pollGetLatestRecord()
    }
  }

}
