// Allowed record metric fields
export const RECORD_FIELD_OPTIONS = [
  'speed',
  'velocity',
  'spin',
  //'duration',
  //'distance',
  //'height',
  'vibra',
  'speedratio'
]

export const formatNumber = (value, fieldType, useImperial = false) => {
  let maxDigits = 1

  // Imperial conversions
  if (useImperial) {
    if (fieldType === 'speed' || fieldType === 'velocity') {
      // Convert km/h to mph
      if (value) {
        value = Math.round(value * 0.621371192)
      }
    }
  }

  if (fieldType === 'duration') {
    // Convert ms to s
    value = value / 1000
    maxDigits = 1
  }

  // Handle zero or empty values
  if (!value) return 0

  let result = (+value).toFixed(maxDigits)
  if (result.match(/\./)) {
    // Trim trailing insignificant zeroes
    result = result.replace(/\.?0+$/, '')
  }

  return result
}

export const League = {
  Amateur: 'amateur',
  Pro: 'pro'
}

export const Gender = {
  Male: 'male',
  Female: 'female'
}

export const MeasurementSystem = {
  Metric: 'metric',
  Imperial: 'imperial'
}
