import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import { useStore } from '../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import Logo from '../../Assets/Images/logo.png'
import { ScoreRow } from '../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: Colors.brandPrimary,
    backgroundImage: 'url(/images/background.jpg)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  },
  logo: {
    height: '4rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: '2rem'
    }
  },
  logoLink: {
    textDecoration: 'none'
  },
  header: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      padding: '.5rem'
    }
  },
  screenTitle: {
    color: Colors.white,
    fontWeight: 600,
    fontSize: '1.75rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  subHeader: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '1rem',
    paddingRight: '1rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      paddingLeft: '.5rem',
      paddingRight: '.5rem',
    }
  },
  subHeaderText: {
    fontWeight: 600,
    fontSize: '1rem',
    color: Colors.white,

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      fontSize: '0.85rem'
    }
  },
  content: {
    padding: '1rem',
    // Mobile styles
    [theme.breakpoints.down('md')]: {
      padding: '.125rem'
    }
  },
  flexSpacer: {
    flex: 1
  },
  divider: {
    height: '2rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      height: '.5rem'
    }
  },
  currentTime: {
    position: 'absolute',
    top: '.5rem',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: Colors.white,
    userSelect: 'none',
    fontWeight: 600,
    letterSpacing: '.1rem',
    fontSize: '1.5rem',

    // Mobile styles
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  headerBorder: {
    marginTop: '1rem',
    marginLeft: '1rem',
    marginRight: '1rem',
    height: '.1875rem',
    background: 'radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0.3) 100%)',

      // Mobile styles
      [theme.breakpoints.down('md')]: {
        height: 1,
        marginTop: '.25rem',
        marginLeft: '.25rem',
        marginRight: '.25rem',
      }
  },
  emptyText: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '40vh',
    paddingTop: '3rem',
    color: Colors.white,
    fontWeight: 600,
    fontSize: '1.5rem',
    textAlign: 'center'
  }
}))

const Profile = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { playerKey } = useParams()

  const { screenStore } = useStore()

  useEffect(() => {
    screenStore.getPlayerDisplay(playerKey, 'default')
  }, [])

  const getScreenTitle = () => {
    const player = get(screenStore.playerDisplay, 'player', null)
    if (player) {
      const fullName = `${player.firstName || ''} ${player.lastName || ''}`.trim()
      return fullName
    }
    return null
  }

  const getScreenName = () => {
    return t('top_scores')
  }

  const handleSetSort = (key) => {
    screenStore.getPlayerDisplay(playerKey, key)
  }

  const renderHeader = () => {
    return (
      <div>
        <div className={classes.header}>
          <a href='/register' className={classes.logoLink}>
            <img src={Logo} className={classes.logo} />
          </a>
          <div className={classes.flexSpacer} />
          <div className={classes.screenTitle}>
            {getScreenTitle()}
          </div>
        </div>
        <div className={classes.subHeader}>
          <div className={classes.subHeaderText}>{t('scoreboard')}</div>
          <div className={classes.flexSpacer} />
          <div className={classes.subHeaderText}>
            {getScreenName()}
          </div>
        </div>
        <div className={classes.headerBorder} />
      </div>
    )
  }

  const renderScores = () => {
    const scores = get(screenStore.playerDisplay, 'topScores', null)

    if (!scores) return null
    if (scores.length === 0) {
      return (
        <div className={classes.emptyText}>
          {t('no_throw_data_yet')}
        </div>
      )
    }

    return scores.map((item, index) => {
      return (
        <ScoreRow
          key={item.id}
          rank={index + 1}
          item={item}
          displayHeading={index === 0}
          currentSortField={get(screenStore.playerDisplay, 'sortField', null)}
          setSort={handleSetSort}
          showAllFields
        />
      )
    })
  }

  return (
    <div className={classes.root}>
      {renderHeader()}
      <div className={classes.content}>
        <div className={classes.divider} />
        {renderScores()}
      </div>
    </div>
  )
}

export default observer(Profile)
