import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../Models/RootStore'
import makeStyles from '@mui/styles/makeStyles'
import { Colors } from '../../Utils/theme'
import Logo from '../../Assets/Images/logo.png'

import { Input, Title, Button } from '../../Components'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    flexDirection: 'row',
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    }
  },
  logo: {
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      width: '8rem',
      paddingTop: '.5rem',
      paddingBottom: '.5rem'
    }
  },
  logoLink: {
    textDecoration: 'none',
    width: '50%',
    [theme.breakpoints.down('lg')]: {
      width: '8rem'
    }
  },
  header: {
    padding: '1rem 1.5rem',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.brandPrimary,
    backgroundImage: 'url(/images/background.jpg)',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      backgroundImage: 'url(/images/background-mobile.jpg)',
    }
  },
  content: {
    flex: 2,
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formContainer: {
    width: '100%',
    maxWidth: '38rem',
    paddingBottom: '1.5rem'
  },
  loginButton: {
    width: '12rem'
  }
}))

const Login = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { sessionStore } = useStore()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = () => {
    sessionStore.login(username, password)
  }

  const renderHeader = () => {
    return (
      <div className={classes.header}>
        <a href="/" className={classes.logoLink}>
          <img src={Logo} className={classes.logo} />
        </a>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div className={classes.formContainer}>
        <Title title={t('login')} />
        <Input
          label={t('username')}
          value={username}
          onChange={setUsername}
        />
        <Input
          label={t('password')}
          value={password}
          onChange={setPassword}
          type='password'
        />
        <Button
          text={t('login')}
          onClick={handleLogin}
          buttonStyle={classes.loginButton}
        />
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {renderHeader()}
      <div className={classes.content}>
        {renderForm()}
      </div>
    </div>
  )
}

export default observer(Login)
